<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="600px"
      @closed="closeModal" @open="openModal">
      <div>
        <ch-form
          :render-option="option"
          :model="modalForm"
          ref="modalForm"
          :rules="rules"
          :props="{ labelWidth: '100px', paddingRight: '0px'}"
        />
      </div>
      <span slot="footer" class="flex_con" v-if="type === 'watch'">
            <ch-button @click="closeModal" type="cancel">关闭</ch-button>
      </span>
      <span slot="footer" class="flex_con" v-else>
            <ch-button @click="closeModal" type="cancel">取消</ch-button>
            <ch-button
              @click="handleModal('modalForm')"
              :loading="isButtonLoading"
              type="ok" >确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { phone } from "@/utils/validator";
import { mapActions } from "vuex";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      editId: '',
      modalForm: {},
      isOpenModal: false,
      isButtonLoading: false,
      type: '',
      rules: {
        // orderNum: [{required: true, message: "请输入部门顺序", trigger: ["blur", "change"],},],
        deptName: [{required: true, message: "请输入部门名称", trigger: ["blur", "change"],},],
        phone: [{ pattern: phone, message: "请输入正确的手机号", trigger: ["blur", "change"],}],
        email: [{ message: "请输入邮箱地址", trigger: ["blur", "change"],},
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
      }
    }
  },
  computed: {
    option: function () {
      if (this.type === 'watch' && this.isOpenModal) {
        return [
          {type: 'text', label: '部门名称：', prop: 'deptName'},
          {type: 'text', label: '负责人：', prop: 'leader' },
          {type: 'text', label: '手机号码：', prop: 'phone' },
          {type: 'text', label: '电子邮箱：', prop: 'email' },
          {type: 'text', label: '部门顺序：', prop: 'orderNum' },
        ]
      }else {
        return [
          {type: 'input', label: '部门名称：', prop: 'deptName', placeholder: "请输入部门名称" },
          {type: 'input', label: '负责人：', prop: 'leader', placeholder: "请输入负责人" },
          {type: 'input', label: '手机号码：', prop: 'phone', placeholder: "请输入手机号码" },
          {type: 'input', label: '电子邮箱：', prop: 'email', placeholder: "请输入电子邮箱" },
          {type: 'number', label: '部门顺序：', prop: 'orderNum', placeholder: "请输入部门顺序" },
        ]
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      getDeptOption: 'system/getDeptOption'
    }),
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs?.modalForm?.clearValidate();
      })
    },
    // 打开查看
    openWatch(row) {
      this.type = 'watch'
      this.title = '查看部门信息'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
    },
    openAdd() {
      this.modalForm = {
        deptName: null, // 部门名称
        leader: null, // 负责人
        email: null, // 电子邮箱
        phone: null, // 手机号码
        orderNum: null, // 部门顺序
      }
      this.type = 'add'
      this.title = '新增部门'
      this.isOpenModal = true
    },
    openEdit(row) {
      this.type = 'edit'
      this.title = '编辑部门信息'
      this.editId = row.deptId
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isButtonLoading = true
          let parma = { ...this.modalForm }
          if(this.type === 'add') {
            this.$curl.post("/hm/dept/add", parma)
              .then(() => {
                this.$message.success("提交成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getDeptOption(true) // 刷新部门下拉数据
              }).catch(() => this.isButtonLoading = false)
          }else {
            parma.deptId = this.editId
            this.$curl.post("/hm/dept/edit", parma)
              .then(() => {
                this.$message.success("提交成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getDeptOption(true) // 刷新部门下拉数据
              }).catch(() => this.isButtonLoading = false)
          }
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.editId = ''
      this.isOpenModal = false
      this.isButtonLoading = false
    },
  }
}
</script>