<template>
  <div>
    <!--  部门管理页面  -->
    <ch-layout button page>

      <template v-slot:button>
        <ch-button @click="openAdd">新增</ch-button>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button
              type="link"
              @click="openEdit(scope.row)">编辑</ch-button>
            <ch-button
              type="link"
              @click="openWatch(scope.row)">查看</ch-button>
            <ch-button
              type="delete"
              @click="handleDelete(scope.row)">删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
          type="old"
          ref="page"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>

    <!-- 查询 编辑 修改 -->
    <dept-modal
      ref="modal"
      :getListFunction="getTableList"
    ></dept-modal>

  </div>
</template>
<script>
import {getTreeSelect, handleDelete} from "@/utils/utils";
import DeptModal from "./deptModal";
import {mapActions, mapGetters} from "vuex";

export default {
  components: { DeptModal },
  data() {
    return {
      tableList: [],
      isTableLoading: false,
      treeSelect: {
        deptremove: false, //删除
        deptedit: false, //编辑
        deptquery: false, //查看
        deptadd: false, //新增
      },
      // 搜索相关
      searchForm: {
        postName: null,
        nickName: null,
      },
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index'},
        {column: 'text', label: '部门名称', prop: 'deptName', showOverflowTooltip: true},
        {column: 'text', label: '负责人', prop: 'leader', showOverflowTooltip: true},
        {column: 'text', label: '部门人员', prop: 'staffName', showOverflowTooltip: true},
        {column: 'slot', label: '操作', slotName: 'handle', width: '120px', fixed: 'right' }
      ]
    },
    ...mapGetters(['userInfo'])
  },
  created() {
    // 获取操作显示权限
    getTreeSelect(this.$route.name).then((res) => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    ...mapActions({
      getDeptOption: 'system/getDeptOption'
    }),
    // 列表数据
    getTableList() {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/dept/list')
        .then(res => {
          this.tableList = res.rows;
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 打开查看
    openWatch(row) {
      this.$refs?.modal.openWatch(row)
    },
    // 打开增加
    openAdd(){
      this.$refs?.modal.openAdd()
    },
    // 打开修改
    openEdit(row) {
      this.$refs?.modal.openEdit(row)
    },
    handleDelete(row) {
      handleDelete("/hm/dept/", row.id).then(() => {
        this.getTableList()
        this.getDeptOption(true)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
